export const TEMP_DATASOURCE_ID = "temp-id-0";
export const DATASOURCE_NAME_DEFAULT_PREFIX = "Untitled datasource ";
export const GOOGLE_SHEET_SPECIFIC_SHEETS_SCOPE =
  "https://www.googleapis.com/auth/drive.file";
export const GOOGLE_SHEET_FILE_PICKER_OVERLAY_CLASS = "overlay";

export const DatasourceCreateEntryPoints = {
  PROPERTY_PANE_CONNECT_DATA: "PROPERTY_PANE_CONNECT_DATA",
  GENERATE_CRUD: "GENERATE_CRUD",
  ENTITY_EXPLORER_ADD_DS: "ENTITY_EXPLORER_DATASOURCE_PLUS_ICON",
  ENTITY_EXPLORER_NEW_DATASOURCE: "ENTITY_EXPLORER_NEW_DATASOURCE",
  ENTITY_EXPLORER_ADD_DS_CTA: "ENTITY_EXPLORER_ADD_DS_CTA",
  ACTIVE_DATASOURCE: "ACTIVE_DATASOURCE",
  CREATE_NEW_DATASOURCE: "CREATE_NEW_DATASOURCE",
  QUERY_EDITOR: "QUERY_EDITOR",
  ONBOARDING: "ONBOARDING",
  NEW_APP_CHECKLIST: "NEW_APP_CHECKLIST",
  CODE_EDITOR_SLASH_COMMAND: "CODE_EDITOR_SLASH_COMMAND",
  OMNIBAR: "OMNIBAR",
  SUBMENU: "SUBMENU",
  ONE_CLICK_BINDING: "ONE_CLICK_BINDING",
};

export const DatasourceEditEntryPoints = {
  DATASOURCE_CARD_EDIT: "DATASOURCE_CARD_EDIT",
  DATASOURCE_FORM_EDIT: "DATASOURCE_FORM_EDIT",
  QUERY_EDITOR_DATASOURCE_SCHEMA: "QUERY_EDITOR_DATASOURCE_SCHEMA",
};

export const DB_QUERY_DEFAULT_TABLE_NAME = "<<your_table_name>>";
export const DB_QUERY_DEFAULT_TEMPLATE_TYPE = "SELECT";
